import { useContext, useEffect, useState } from 'react';
import { Box, Dialog, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material';
import { useMediaQuery, useTheme } from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import CloseIcon from '@mui/icons-material/Close';
import { CurrentFormContext } from './ToDoList';
import { Model } from 'survey-core';
import { Survey } from 'survey-react-ui';
import 'survey-core/defaultV2.min.css';
import { InceptiaLight } from '../themes/surveyjs-inceptia-theme';
import formsService from "../services/FormsService";
import { iDocumentDTO, iSubmission, iTodo } from '../utilities/APIInterfaces';
import { AutoSaveState, Status } from '../utilities/FormEnums';
import { UserContext } from '../contexts/UserContext';
import { useSnackbar } from 'notistack';
import userService from '../services/UserService';
import KeycloakService from '../services/KeycloakService';
import { handleClearFiles, handleUploadFiles } from '../utilities/SurveyJS';

type OpenFormModalProps = {
  openForm: iTodo | null;
  setTodoListView: React.Dispatch<React.SetStateAction<'active' | 'completed'>>;
};

let autoSaveState : AutoSaveState = AutoSaveState.INITIAL;
let dataHasChanged = false;
let isRetakingForm = false;
let switchToActiveTab = false;
let submissionId: number | undefined | null;

export default function OpenFormModal(props : OpenFormModalProps) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const userContext = useContext(UserContext);
  const { openFormIsOpen, setOpenFormIsOpen } = useContext(CurrentFormContext);
  const { setOpenContributorDialog } = useContext(CurrentFormContext);
  const [surveyModel, setSurveyModel] = useState<Model>();
  let submissionDocuments: iDocumentDTO[] = [];
  let isContributor = props.openForm?.ownedBy != userContext.user?.id
  const HIDE_FOR_PARENT = "HIDE_FOR_PARENT";
  const HIDE_FOR_STUDENT = "HIDE_FOR_STUDENT";
  let hidePanelsFor = isContributor ? HIDE_FOR_PARENT : HIDE_FOR_STUDENT; 
  

  const { enqueueSnackbar } = useSnackbar();
  
  function OnSurveyCommitHandler(model : Model, options: any) {
    if (model) {
      // FEBS-1166 only save on submit if something was changed if the status is ACTION_NEEDED
      if (dataHasChanged || props.openForm?.statusId !== Status.ACTION_NEEDED) {

        const data = model.data;
        data.pageNo = model.currentPageNo;
        autoSaveState = AutoSaveState.DATA_NOT_CHANGED;

        SaveSurveyDataAsync(
          {
            id: submissionId || -1,
            uuid: props.openForm?.formSubmissionUuid,
            formsVersionId: props.openForm?.formVersionId || -1,
            submittedBy: userContext.user?.id || -1,
            updatedBy: userContext.user?.id || -1,
            submissionJson: JSON.stringify(data),
            submissionTypeId: 1, // default to student
            orgId: props.openForm?.orgId || -1,
            statusId: Status.SUBMITTED,
            documents: submissionDocuments,
            isRetakingForm: isRetakingForm,
            isSubmittingForm: true
          }).then((result) => {
            autoSaveState = AutoSaveState.INITIAL;
            if (result?.statusId == Status.PARENT_INFO_NEEDED) {
              setOpenFormIsOpen(false);
              setOpenContributorDialog(true);
            }
            else {
              if (switchToActiveTab) props.setTodoListView('active');
              setOpenFormIsOpen(false);
            }
          });
      }
      else {
        if (switchToActiveTab) props.setTodoListView('active');
        setOpenFormIsOpen(false);
      }
}
  };

  function SurveyDataChanged () {
    if (autoSaveState !== AutoSaveState.LOADING) {
      autoSaveState = AutoSaveState.SAVE_NEEDED;
      dataHasChanged = true;
    }
  }

  function SaveSurveyData(model?: Model) {
    if (model) {
      if (autoSaveState === AutoSaveState.SAVE_NEEDED) {
        if (isRetakingForm) switchToActiveTab = true;
        const data = model.data;
        data.pageNo = model.currentPageNo;
        autoSaveState = AutoSaveState.DATA_NOT_CHANGED;

        SaveSurveyDataAsync(
          {
            id: submissionId || -1,
            uuid: props.openForm?.formSubmissionUuid,
            formsVersionId: props.openForm?.formVersionId || -1,
            submittedBy: userContext.user?.id || -1,
            updatedBy: userContext.user?.id || -1,
            submissionJson: JSON.stringify(data),
            submissionTypeId: 1, // default to student
            orgId: props.openForm?.orgId || -1,
            statusId: Status.INCOMPLETE,
            documents: submissionDocuments,
            isRetakingForm: isRetakingForm,
            isSubmittingForm: false
          });
      }
    }
  }

  async function SetupSurvey() {
    let surveyModel = new Model(JSON.parse(props.openForm?.formJson || ""));
    surveyModel.applyTheme(InceptiaLight);
    surveyModel.onComplete.add((model,options) => OnSurveyCommitHandler(model,options));
    surveyModel.onValueChanged.add(SurveyDataChanged);
    surveyModel.onCurrentPageChanged.add((model) =>SaveSurveyData(model)); 
    surveyModel.onUploadFiles.add(async (_, options) => {
      if (userContext.user){
        submissionDocuments = await handleUploadFiles(userContext.user?.authId!, userContext.user?.authId!, options, submissionDocuments, enqueueSnackbar);
      }
    });
  
    surveyModel.onClearFiles.add(async (_, options) => {
      submissionDocuments = await handleClearFiles(userContext.user?.authId!, options, submissionDocuments, enqueueSnackbar);
    });

    // If form's status is declined or ineligible and the forms_version.CAN_RETAKE field is true, open the form "fresh" (without the previous content).
    if (props.openForm?.statusId && props.openForm.canRetake && [Status.DECLINED, Status.INELIGIBLE].includes(props.openForm.statusId)) {
      surveyModel.data = null;
      surveyModel.currentPageNo = 0;
      autoSaveState = AutoSaveState.DATA_NOT_CHANGED;
      isRetakingForm = true;
      setSurveyModel(surveyModel);
      return null;
    }

    let authId = userContext.user?.authId?.toString() || "";
    autoSaveState = AutoSaveState.LOADING;
    await userService.GetUserSubmission(
      authId,
      props.openForm?.formSubmissionUuid || "",
      (v) => v
      ).then((result) => {
        if (result) {
        let origSubmissionData:iSubmission = result;
        submissionDocuments = origSubmissionData.documents;
        let data = JSON.parse(origSubmissionData.submissionJson);
        surveyModel.data = data;
        submissionId = origSubmissionData.id;
        if (data.pageNo) {
          surveyModel.currentPageNo = data.pageNo;
        }
        } else {
          surveyModel.data = null;
          surveyModel.currentPageNo = 0;
          submissionId = null;
        }
        //don't auto populate if the user is a contributor 
        if(!isContributor){
          autoPopulateIf(surveyModel);
        }
        //hide the panels that are not needed
        //for some reason .visible isn't accessible without setting the type to any
        surveyModel.clearInvisibleValues = false;
        surveyModel.getAllPanels().map((panel: any) => {
          if (panel.name.includes(hidePanelsFor)) {
            panel.visible = false;
          }
        });
        autoSaveState = AutoSaveState.DATA_NOT_CHANGED;
        setSurveyModel(surveyModel);
      })
  }

  async function SaveSurveyDataAsync(newSubmissionData : iSubmission) : Promise<iSubmission | null> {
    return await formsService.SaveFormSubmission(newSubmissionData, (v) => v)
      .then((result) => {
        submissionId = result?.id;
        return result;
      })
      .catch((error) => {
        enqueueSnackbar(error.toString());
        return null;
      }).finally(() => {
        isRetakingForm = false;
      });
  }

  function autoPopulateIf(surveyObj: Model) {
    const mapping = formsService.GetAutofillMapBy(userContext?.user, props.openForm?.orgId ?? 0);
    Object.entries(mapping).map((kvp) => {
      let question = surveyObj.getQuestionByName(kvp.at(0) ?? "");
      if (question) {
        question.value = kvp.at(1) ?? "";
      }
    });
  }

  useEffect(() => {
    if (openFormIsOpen && autoSaveState === AutoSaveState.INITIAL || surveyModel === undefined) {
      SetupSurvey();
    }
  }, [openFormIsOpen]);

    
  const handleClose = () => {
    if (autoSaveState === AutoSaveState.SAVE_NEEDED) switchToActiveTab = true;
    
    // if the handler fires more than once
    // FEBS-1166 only save on submit if something was changed if the status is ACTION_NEEDED
    if (dataHasChanged || props.openForm?.statusId !== Status.ACTION_NEEDED) {
       SaveSurveyData(surveyModel);
    }
    setOpenFormIsOpen(false);
    autoSaveState = AutoSaveState.INITIAL;
  
    if (switchToActiveTab) props.setTodoListView('active');
  }

  return (
    <Dialog
      open={openFormIsOpen}
      onClose={handleClose}
      maxWidth='lg'
      fullScreen={fullScreen}
      PaperProps={{
        sx: { borderRadius: { xs: 0, md: 2 }}
      }}
      sx={{
        height: { xs: '100%', md: 'initial' },
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: {sx: "100%", md: "90%" },
            maxWidth: "1175px",
          },
        },        
      }}
      aria-labelledby='form-modal-title'
      aria-describedby='form-modal-content'
    >
      <DialogTitle id='form-modal-title' sx={{ p: 1, display: 'flex' }}>
        {/* Fix "Empty header" accessibility error by adding a "visually hidden" element for the dialog title */}
        <Typography variant='body1' sx={visuallyHidden}>Open Form Modal</Typography>
        <IconButton aria-label='Close' onClick={handleClose} sx={{ ml: 'auto' }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent id='form-modal-content' sx={{
        mb: 0,
        p: 0,

        // Hide the "Clear" (eraser icon) and "Remove this file" options for students
        // Don't want students to delete files that have been uploaded
        ...(KeycloakService.getGroups().length === 0 ? {
          '& #sv-file-clean': {
            display: 'none'
          },
          '& .sd-file__preview-item .sd-file__remove-file-button': {
            display: 'none !important'
          }
        } : {})
      }}>
        { surveyModel && <Survey model={surveyModel} />}
        {!surveyModel && 
          <Typography variant='h5' sx={{ mb: 0, textAlign:'center' }}>
              Loading...
          </Typography>
    }
      </DialogContent>
    </Dialog>
  )
}
