import { useCallback, useContext, useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import { pageHeaderSX } from '../../utilities/CSS';
import CustomTabs, { CustomTabPanel } from '../CustomTabs';
import NotificationTemplateCrud from "./NotificationTemplateCrud";
import NotificationScheduleCrud from "./NotificationScheduleCrud";
import { OrganizationContext, OrganizationContextProvider } from '../../contexts/OrganizationContext';
import KeycloakService from '../../services/KeycloakService';
import notificationService from '../../services/NotificationService';
import { NotificationTemplateDto } from '../../apis/notifications';

export default function DashboardCenterNotifications() {
    const [selectedTab, setSelectedTab] = useState(0);
    const [isInceptiaOrganization, setIsInceptiaOrganization] = useState<boolean>(false);
    const [orgID, setOrgID] = useState<number | undefined>(undefined);
    const [opeid, setOpeid] = useState<string | undefined>(undefined);
    const { organization } = useContext(OrganizationContext);
    const [isInceptiaUser, setIsInceptiaUser] = useState<boolean>(false);
    const [isLinkDialogOpen, setIsLinkDialogOpen] = useState<boolean>(false);
    const [templates, setTemplates] = useState<NotificationTemplateDto[]>([]);

    useEffect(() => {
        if (organization?.opeid === "INCEPTIA")
            setIsInceptiaOrganization(true);
        else 
            setIsInceptiaOrganization(false);
        setOpeid(organization?.opeid);
        setOrgID(organization?.id);
    }, [organization?.opeid, organization?.id]);

    // for now managers and operations are just like institution users
    useEffect(() => {
        setIsInceptiaUser(["Admin"].some(g => KeycloakService.hasGroup([g])));
    }, []);

    const handleTabChange = (event: React.SyntheticEvent, newTab: number) => {
        setSelectedTab(newTab);
    };

    const fetchTemplates = useCallback(async () => {
        if(!isLinkDialogOpen && orgID)
        {
            let templates = await notificationService.GetTemplatesByOrgId(orgID);
            if(templates)
                templates = templates.sort((a, b) => (a.id ?? 0) - (b.id ?? 0));
            setTemplates(templates);
            return templates;    
        }
        return [];
    }, [orgID, isLinkDialogOpen]);

    return (
        <>
            <OrganizationContextProvider>
                <Typography variant='h1' sx={{ ...pageHeaderSX, mb: 4.5 }}>Notifications</Typography>

                <CustomTabs
                    ariaTabAndPanelPrefix='notifications'
                    aria-label='Notifications Tabs'
                    allowScrollButtonsMobile
                    selectedTab={selectedTab}
                    variant='scrollable'
                    tabs={[
                        { label: 'Manage Templates' },
                        { label: 'Manage Schedules' },
                    ]}
                    handleTabChange={handleTabChange}
                />
                <CustomTabPanel index={0} selectedTab={selectedTab}>
                    <NotificationTemplateCrud 
                        isLinkDialogOpen={isLinkDialogOpen}
                        setIsLinkDialogOpen={setIsLinkDialogOpen}
                        fetchTemplates={fetchTemplates}
                        isInceptiaOrganization={isInceptiaOrganization} 
                        orgId={orgID} 
                        opeid={opeid}
                        isInceptiaUser={isInceptiaUser} />
                </CustomTabPanel>
                <CustomTabPanel index={1} selectedTab={selectedTab} autoHeight>
                    <NotificationScheduleCrud 
                        templates={templates} 
                        orgId={orgID} 
                        isInceptiaUser={isInceptiaUser} />
                </CustomTabPanel>
            </OrganizationContextProvider>
        </>
    );
}
