import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, CircularProgress, Container, Paper, Skeleton, Typography } from '@mui/material';
import { BackToFormsLink } from '../AdminCenterForm';
import { AdminFormDataContext } from '../../contexts/AdminFormDataContext';
import { OrganizationContext } from '../../contexts/OrganizationContext';
import { UserContext } from '../../contexts/UserContext';
import { cardSX, pageHeaderSX } from '../../utilities/CSS';
import FormCrudBasicInfo, { BasicInfoDataType } from './FormCrudBasicInfo';
import FormCrudTags from './FormCrudTags';
import FormCrudBilling, { BillingDataType } from './FormCrudBilling';
import adminFormsService, { AdminFormSaveType } from '../../services/AdminFormsService';
import { useSnackbar } from 'notistack';
import { iTagDTO } from '../../utilities/APIInterfaces';

const FormAdd = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { organization, isOrganizationDataLoaded } = useContext(OrganizationContext);
  const userContext = useContext(UserContext);
  const { setIsAdminFormDataLoaded } = useContext(AdminFormDataContext);
  const [createFormButtonClicked, setCreateFormButtonClicked] = useState(false);
  const [isBasicInfoDataValid, setIsBasicInfoDataValid] = useState(false);
  const [isBillingDataValid, setIsBillingDataValid] = useState(false);
  const [basicInfoData, setBasicInfoData] = useState<BasicInfoDataType>({
    uuid: crypto.randomUUID(),
    name: '',
    start_Date: '',
    verifier_Type_Id: 0,
    visibility_Type_Id: 0,
    priority_Id: 0
  });
  const [billingData, setBillingData] = useState<BillingDataType>({});
  const [isSaving, setIsSaving] = useState(false);
  const [tags, setTags] = useState<iTagDTO[]>([]);

  useEffect(() => {
    if (isBasicInfoDataValid && isBillingDataValid) {
      setIsSaving(true);

      const saveForm: AdminFormSaveType = {
        org_Id: organization!.id,
        userId: userContext.user?.id,
        academic_Aid_Year_Id: 1,
        ...basicInfoData,
        ...billingData,
        tags: tags
      };

      adminFormsService.CreateForm(saveForm, (v) => v)
        .then()
        .catch(error => {
          enqueueSnackbar(error.toString());
        }).finally(() => {
          setIsAdminFormDataLoaded(false);
          navigate('/admin/forms');
        });

    }
  }, [isBasicInfoDataValid, isBillingDataValid]);

  const handleCreateFormButtonClick = () => {
    setCreateFormButtonClicked(true);
  };

  return (<>
    {/* Loading... */}
    { ! isOrganizationDataLoaded && (<>
      <Skeleton animation='pulse' variant='text' sx={{ height: '20px', mt: 0.5, mb: 1.25, transform: 'none' }} width={150} />
      <Skeleton animation='pulse' variant='text' sx={{ height: '37px', mb: 2.25, transform: 'none' }} width={400} />
    </>)}

    {/* Loaded... */}
    {isOrganizationDataLoaded && (<>
      <BackToFormsLink />
      <Typography variant='h1' sx={{ ...pageHeaderSX, mb: 2.25 }}>Add Form</Typography>
    </>)}

    <Paper sx={cardSX.paperMinHeight}>
      <Container sx={{ p: { xs: 2.25, sm: 4.5 } }}>
        <FormCrudBasicInfo
          action='add'
          createFormButtonClicked={createFormButtonClicked}
          setCreateFormButtonClicked={setCreateFormButtonClicked}
          basicInfoData={basicInfoData}
          setBasicInfoData={setBasicInfoData}
          setIsBasicInfoDataValid={setIsBasicInfoDataValid}
        />

        <FormCrudTags
          action='add'
          tags={tags}
          setTags={setTags}
        />

        <FormCrudBilling
          action='add'
          createFormButtonClicked={createFormButtonClicked}
          setCreateFormButtonClicked={setCreateFormButtonClicked}
          billingData={billingData}
          setBillingData={setBillingData}
          setIsBillingDataValid={setIsBillingDataValid}
        />

        <Box sx={{ textAlign: 'right' }}>
          <Button 
            variant='contained'
            disabled={isSaving}
            onClick={handleCreateFormButtonClick}
            sx={{
              fontSize: '1rem',
              textTransform: 'none',
              bgcolor: 'InceptiaGreen.main',
              '&:hover': {
                backgroundColor: 'InceptiaGreen.dark',
              }
            }}
          >
          {isSaving ? (
            <>
              <CircularProgress
                color='inherit'
                size={20}
                sx={{ mr: 1.5 }}
              />
              Creating...
            </>
          ) : 'Create Form'}</Button>
        </Box>
      </Container>
    </Paper>
  </>);
};
export default FormAdd;