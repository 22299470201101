import { 
  Autocomplete,
  Box, 
  Button, 
  ListItemText, 
  Menu, 
  MenuItem, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  TextField, 
  Typography 
} from '@mui/material';
import { AdminFormCrudActionType, AdminFormCrudProps } from '../../pages/AdminFormCrud';
import { visuallyHidden } from '@mui/utils';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { inceptiaGreenAlphaColors } from '../../utilities/CSS'
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { NotificationCommunicationTypeEnum, NotificationScheduleDto, NotificationScheduleDtoIntervalTypeEnum, NotificationTemplateDto, NotificationTemplateStatusEnum } from '../../apis/notifications';
import { OrganizationContext } from '../../contexts/OrganizationContext';
import notificationService from '../../services/NotificationService';
import { AdminFormDataContext } from '../../contexts/AdminFormDataContext';
import { RoleRouteMapper } from '../RoleRouteMapper';
import { AdminRoleNodes } from '../../utilities/APIInterfaces';
import ConfirmationDialog from '../ConfirmationDialog';
import KeycloakService from '../../services/KeycloakService';

const comTableSx = {
  tableHead: {
    fontSize: '14px',
    fontWeight: '500',
    p: 1.5,
    pt: 0,
    textAlign: 'left',
    textTransform: 'uppercase'
  },
  tableCell: {
    textAlign: 'left'
  }
};

type CommunicationData = {
  id: number;
  name?: string;
  type?: NotificationCommunicationTypeEnum;
  maxAttempts?: number | null;
  interval_type?: NotificationScheduleDtoIntervalTypeEnum;
  interval_value?: number;
  notification_template_id?: number;
}


const FormCrudCommunications = (props: AdminFormCrudProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [data, setData] = useState<CommunicationData[]>([]);
  const open = Boolean(anchorEl);
  const { organization } = useContext(OrganizationContext);
  const [templates, setTemplates] = useState<NotificationTemplateDto[]>([]);
  const [schedules, setSchedules] = useState<NotificationScheduleDto[]>([]);
  const {formData } = useContext(AdminFormDataContext);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);
  const [isAddDialogOpen, setIsAddDialogOpen] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const templateNameTextRef = useRef();
  const [selectedTemplates, setSelectedTemplates] = useState<NotificationTemplateDto[]>([]);
  const [linkToDelete, setLinkToDelete] = useState<CommunicationData | null>(null);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);

  useEffect(() => {
    setIsAdmin(KeycloakService.hasGroup(['Admin']));
  }, []);

  useEffect(() => {
    let getMetadata = async () => {
      if(organization?.id) {
        let searchedScheds = notificationService.GetSchedulesByOrgId(organization.id);
        let searchedTemps = await notificationService.GetTemplatesByOrgId(organization.id) ?? [];
        searchedTemps = searchedTemps.filter(t => t.status === NotificationTemplateStatusEnum.Approved);
        setTemplates(searchedTemps);
        setSchedules(await searchedScheds ?? []);
      }
    };
    getMetadata();
  }, [organization?.id]);

  let getLinks = useCallback(async() => {
    if(formData?.uuid) {
      let formTemplateLinks = await notificationService.GetFormTemplateLinks(formData.uuid) ?? [];
      let tableData = formTemplateLinks.map(l => {
        let matchingTempaltes : NotificationTemplateDto[] = templates.filter(t => t.id === l.notification_template_id);
        let template : NotificationTemplateDto | null = matchingTempaltes ? matchingTempaltes[0] : null;
        let matchingSchedules : NotificationScheduleDto[] = template == null ? [] : 
          schedules.filter(s => s.notification_template_id === template.id);
        let schedule : NotificationScheduleDto | null = matchingSchedules ? matchingSchedules[0] : null;

        let result : CommunicationData = {
          id: l.id,
          notification_template_id: l.notification_template_id,
          name: template?.name,
          type: template?.communication_type,
          interval_type: schedule?.interval_type,
          interval_value: schedule?.interval_value,
          maxAttempts: schedule?.max_notification_attempts
        };
        return result;
      });

      setData(tableData);
    } 
  }, [formData?.uuid, schedules, templates]);

  useEffect(() => {
    getLinks();
  }, [getLinks]);

  useEffect(() => {
    let saveLinks = async() => {
      let selectedTemplateIds = selectedTemplates?.map(t => t.id);
      if (isAdding && selectedTemplateIds && formData?.uuid) {
        await notificationService.AddFormTemplateLinks(formData.uuid, selectedTemplateIds);
        await getLinks();
      }
    };
    saveLinks();
    setIsAdding(false);
    setSelectedTemplates([]);
    setIsAddDialogOpen(false);
  // we don't want to trigger adding templates other than when we setIsAdding(true)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAdding]);

  useEffect(() => {
    let deleteLink = async() => {
      if (isDeleting && linkToDelete?.id) {
        await notificationService.DeleteFormTemplateLink(linkToDelete.id);
        await getLinks();
      }
    };
    deleteLink();
    setIsDeleting(false);
    setIsDeleteDialogOpen(false);
  // we don't want to trigger deletes except when setIsDeleting(true)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDeleting]);

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>, row: CommunicationData) => {
    // Don't want to trigger the table row click event handler when clicking on the button
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setLinkToDelete(row);
  };

  const handleMenuClose = (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  return (<>
    <RoleRouteMapper 
      roles={Object.keys(new AdminRoleNodes())
        .filter((role) => ['admin'].includes(role))}
    >
      {isAdmin && <Button 
        variant="contained"
        sx={{
          fontSize: '1rem',
          float:'right',
          textTransform: 'none',
          bgcolor: 'InceptiaGreen.main',
          '&:hover': {
            backgroundColor: 'InceptiaGreen.dark',
          },
        }}
        onClick={() => setIsAddDialogOpen(true)}
      >+ Add Templates</Button>}
    </RoleRouteMapper>
    <Typography variant='h2' sx={{ fontSize: '1.5rem', fontWeight: 500, mb: 4.5 }}>Communications</Typography>
    <TableTemplate action={props.action} children={<>
      {data.map(row => {
        return <TableRow
          hover
          role='button' // Keyboard accessibility
          key={`link-${row.id}`}
          sx={{
            //  Figma screen is showing the bottom border of the last row
            // '&:last-child td, &:last-child th': { border: 0 },
            '&:focus-visible': { backgroundColor: inceptiaGreenAlphaColors.hover }
          }}
          tabIndex={0} // Keyboard accessibility
        >
          <TableCell sx={{
            color: 'InceptiaGreen.main',
            fontSize: '1rem',
            fontWeight: '500',
            px: 1
          }}>{row.name}</TableCell>

          <TableCell sx={comTableSx.tableCell}>{row.type}</TableCell>
          <TableCell sx={comTableSx.tableCell}>{row.maxAttempts}</TableCell>
          <TableCell sx={comTableSx.tableCell}>{row.interval_type}</TableCell>
          <TableCell sx={comTableSx.tableCell}>{row.interval_value}</TableCell>

          <TableCell sx={{...comTableSx.tableCell, pr: 0 }}>
            <Button
              id="basic-button"
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              color='inherit'
              onClick={e => handleMenuClick(e, row)}
              sx={{ minWidth: 'revert', ml: 'auto' }}
            >
              <MoreHorizIcon />
            </Button>
          </TableCell>
        </TableRow>
    })}</>}/>

    <Menu
      id="basic-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={handleMenuClose}
      MenuListProps={{
        'aria-labelledby': 'more-button',
        dense: true,
        sx: {
          minWidth: '180px',
          textAlign: 'right'
        }
      }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
    >
      <MenuItem onClick={handleMenuClose} disabled={!isAdmin}>
        <ListItemText onClick={() => setIsDeleteDialogOpen(true)} primaryTypographyProps={{ fontWeight: '500' }}>Delete Communication</ListItemText>
      </MenuItem>
    </Menu>

    {isDeleteDialogOpen && <ConfirmationDialog
      ariaPrefix='delete-template'
      dialogTitle='Delete Template'
      singleButton={false}
      ctaButtonText='Delete'
      ctaButtonWorkingText='Deleting...'
      cancelButtonText='Cancel'
      open={isDeleteDialogOpen}
      setOpenDialog={setIsDeleteDialogOpen}
      isWorking={(isDeleting)}
      setIsWorking={setIsDeleting}
    >
      <Typography variant='body2' sx={{ mb: 2 }}>Are you sure you want to unlink {linkToDelete?.name}?</Typography>
    </ConfirmationDialog>}

    {isAddDialogOpen && <ConfirmationDialog
      ariaPrefix='add-templates'
      dialogTitle='Add Templates'
      singleButton={false}
      ctaButtonText='Save'
      ctaButtonWorkingText='Saving...'
      cancelButtonText='Cancel'
      open={isAddDialogOpen}
      setOpenDialog={setIsAddDialogOpen}
      isWorking={(isAdding)}
      setIsWorking={setIsAdding}
    >
      <Autocomplete
        id={`add-templates-autocomplete`}
        getOptionLabel={(option: NotificationTemplateDto) => option.name || ''}
        multiple={true}
        options={templates.filter(t => !data.some(row => row.notification_template_id === t.id))}
        value={selectedTemplates}
        disableClearable={true}
        onChange={(e, value: any) => {
          let myArray: NotificationTemplateDto[] = value;
          setSelectedTemplates(myArray);
        }}
        renderInput={(params) =>
            <TextField
                inputRef={templateNameTextRef}
                {...params}
                label='Templates'
                />}
        //Style the selected items in the dropdown list
        slotProps={{
            popper: {
                sx: {
                    '& .MuiAutocomplete-listbox .MuiAutocomplete-option.Mui-focused': {
                        backgroundColor: inceptiaGreenAlphaColors.hover
                    },
                    '& .MuiAutocomplete-listbox .MuiAutocomplete-option[aria-selected="true"]': {
                        backgroundColor: inceptiaGreenAlphaColors.selected
                    },
                    '& .MuiAutocomplete-listbox .MuiAutocomplete-option[aria-selected="true"].Mui-focused': {
                        backgroundColor: inceptiaGreenAlphaColors.selectedHover
                    }
                }
            }
        }}
        sx={{ width: '98%', marginTop: "10px" }}
    />
    </ConfirmationDialog>}
  </>);
};

const TableTemplate = ({ action, children }: { action: AdminFormCrudActionType, children: React.ReactNode }): JSX.Element => {
  return (
    <TableContainer sx={{
      ...(action === 'add' && { mb: 6.25 })
    }}>
      <Table aria-label="to-do list table" sx={{justifyContent: 'left'}}>
        <TableHead>
          <TableRow>
            <TableCell component="th" sx={comTableSx.tableHead}>Template Name</TableCell>
            <TableCell component="th" sx={comTableSx.tableHead}>Type</TableCell>
            <TableCell component="th" sx={comTableSx.tableHead}>Max Attempts</TableCell>
            <TableCell component="th" sx={comTableSx.tableHead}>Interval Type</TableCell>
            <TableCell component="th" sx={comTableSx.tableHead}>Interval Value</TableCell>
            <TableCell component="th" sx={comTableSx.tableHead}>
              {/* Fix for empty header cell accessibility error */}
              <Box sx={visuallyHidden}>Actions</Box>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {children}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default FormCrudCommunications;
