import React, { useState, useEffect, useCallback } from 'react';
import { Alert, Box, Button, Container, Grid, Typography, CircularProgress, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';
import ConfirmationDialog from '../ConfirmationDialog';
import { inceptiaGreenAlphaColors } from '../../utilities/CSS';

interface BaseModel {
    id?: number;
    name?: string;
}

export interface CustomCrudButton<T> {
    text: string,
    action: (entity: T) => void
}

type CrudPageProps<T extends BaseModel> = {
    title: string;
    entityName: string;
    initialValues: T;
    validationSchema: yup.ObjectSchema<any>;
    fetchItems: () => Promise<T[]>;
    isDefault: (value: T) => boolean;
    customSaveText?: (value: T | null) => string;
    createItem: (values: T) => Promise<void>;
    updateItem: (id: number, values: T) => Promise<void>;
    deleteItem: (value: T) => Promise<void>;
    renderFormFields: (formik: any, isCreate: boolean) => JSX.Element;
    renderTableColumns: (item: T) => JSX.Element;
    renderTableHeaders: () => JSX.Element;
    customRowButtons?: CustomCrudButton<T>[];
    customEditButtons?: CustomCrudButton<T>[];
    canAddNew: boolean;
    handleSubmitButtonClick?: (formik: any) => Promise<void>;
};

export const DropdownStyles = {
    '& .MuiInputLabel-root': {
        '&.Mui-focused': {
            color: 'InceptiaGreen.main', 
        },
    },
    '& .MuiOutlinedInput-root': {
        '&:hover fieldset': {
            borderColor: 'InceptiaGreen.main', 
        },
        '&.Mui-focused fieldset': {
            borderColor: 'InceptiaGreen.main', 
        },
    },
};

export default function ManageCrudPage<T extends BaseModel>({
                                                        title,
                                                        entityName,
                                                        initialValues,
                                                        validationSchema,
                                                        fetchItems,
                                                        isDefault,
                                                        customSaveText,
                                                        createItem,
                                                        updateItem,
                                                        deleteItem,
                                                        renderFormFields,
                                                        renderTableColumns,
                                                        renderTableHeaders,
                                                        customRowButtons,
                                                        customEditButtons,
                                                        canAddNew,
                                                        handleSubmitButtonClick
                                                    }: CrudPageProps<T>) {
    const [items, setItems] = useState<T[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [editItem, setEditItem] = useState<T | null>(null);
    const [isSaving, setIsSaving] = useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [itemToDelete, setItemToDelete] = useState<T | null>(null);
    const [isFormVisible, setIsFormVisible] = useState(false);

    const formik = useFormik<T>({
        initialValues,
        validationSchema,
        onSubmit: async (values, { resetForm }) => {
            try {
                setIsSaving(true);
                if (editItem) {
                    await updateItem(editItem.id!, values);
                } else {
                    await createItem(values);
                }
                fetchItemsList();
                resetForm();
                setEditItem(null);
                setIsFormVisible(false);
            } catch (error) {
                console.error('Error saving item:', error);
            } finally {
                setIsSaving(false);
            }
        },
    });

    const fetchItemsList = useCallback(async () => {
        setIsLoading(true);
        try {
            const data = await fetchItems();
            setItems(data);
        } catch (error) {
            console.error('Error fetching items:', error);
        } finally {
            setIsLoading(false);
        }
    }, [fetchItems]);

    const handleEdit = (item: T) => {
        setEditItem(item);
        formik.setValues(item);
        setIsFormVisible(true);
    };

    const handleDelete = async (isWorking: boolean) => {
        if (isWorking && itemToDelete?.id) {
            try {
                await deleteItem(itemToDelete);
                fetchItemsList();
            } catch (error) {
                console.error('Error deleting item:', error);
            } finally {
                setDeleteDialogOpen(false);
                setItemToDelete(null);
            }
        }
    };

    const handleCancel = () => {
        formik.resetForm();
        setEditItem(null);
        setIsFormVisible(false);
    };

    useEffect(() => {
        fetchItemsList();
    }, [fetchItemsList]); 

    const mainSX = {
        backgroundColor: "InceptiaGreen.main",
        "&:hover": {
            backgroundColor: "InceptiaGreen.dark"
        }
    };

    const secondarySX = {
        color: "InceptiaGreen.main",
        borderColor: "InceptiaGreen.main",
        "&:hover": {
            borderColor: "InceptiaGreen.dark",
            backgroundColor: inceptiaGreenAlphaColors.hover
        }
    };

    const sideSX = {
        color: "InceptiaGreen.main",
        "&:hover": {
            color: "InceptiaGreen.dark"
        }
    };

    return (
        <Container>
            <Typography variant='h2' sx={{ fontSize: '1.5rem', fontWeight: 500, mb: 4.5, pt:2 }}>
                {title}
            </Typography>
            {!isFormVisible ? (
                <>
                    {canAddNew && <Button
                        variant="contained"
                        color="primary"
                        sx={mainSX}
                        onClick={() => {
                            formik.resetForm();
                            setEditItem(null);
                            setIsFormVisible(true);
                        }}
                    >
                        {`Add New ${entityName}`}
                    </Button>}
                    {isLoading ? (
                        <CircularProgress />
                    ) : (
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        {renderTableHeaders()}
                                        <TableCell>Actions</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {items && items.map((item, index) => (
                                        <TableRow key={`${index}-${item.id}`}>
                                            {renderTableColumns(item)}
                                            <TableCell>
                                                {customRowButtons && customRowButtons.map((button, index) => <>
                                                    <Button
                                                        key={`customRowButton${index}`}
                                                        sx={sideSX}
                                                        onClick={()=> button.action(item)}>{button.text}</Button>
                                                </>)}
                                                <Button 
                                                    sx={sideSX} 
                                                    onClick={() => handleEdit(item)}>{isDefault(item) ? "View" : "Edit"}</Button>
                                                {!isDefault(item) && <Button 
                                                    sx={sideSX} 
                                                    onClick={() => {
                                                        setItemToDelete(item);
                                                        setDeleteDialogOpen(true);
                                                    }}>
                                                    Delete
                                                </Button>}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )}
                </>
            ) : (
                <Box component="form" noValidate onSubmit={formik.handleSubmit} sx={{ pb: 2.5 }}>
                    <Grid container gap={2}>
                      {renderFormFields(formik, editItem === null)}
                      {(!isDefault(formik.values) || !editItem) && <Button
                        sx={mainSX}
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={isSaving}
                        onClick={() => handleSubmitButtonClick?.(formik)}
                    >
                            {isSaving ? <CircularProgress size={24} /> : customSaveText ? 
                                customSaveText(editItem) : editItem ? 'Update Item' : 'Create Item'}
                        </Button>}
                        <Button variant="outlined" color="warning" onClick={handleCancel} sx={secondarySX}>
                            Cancel
                        </Button>
                        {customEditButtons && customEditButtons.map((button, index) => <>
                            <Button
                                variant="contained" 
                                color="primary" 
                                key={`customEditButton${index}`}
                                sx={{...mainSX, ml: 'auto' }}
                                onClick={async ()=> {
                                    await handleSubmitButtonClick?.(formik);
                                    let errors = await formik.validateForm();
                                    let touched: any = {};
                                    for(var key of Object.keys(formik.values))
                                        touched[key] = true;
                                    formik.setErrors(errors);
                                    formik.setTouched(touched);
                                    if (Object.keys(errors).length === 0)
                                        button.action(formik.values);
                                }}>{button.text}</Button>
                        </>)}
                    </Grid>
                </Box>
            )}

            <ConfirmationDialog
                ariaPrefix={`delete-${entityName}`}
                open={deleteDialogOpen}
                dialogTitle='Are you sure?'
                ctaButtonText="Delete"
                ctaButtonWorkingText="Deleting..."
                singleButton={false}
                cancelButtonText="Cancel"
                setOpenDialog={setDeleteDialogOpen}
                isWorking={false}
                setIsWorking={handleDelete}
            >
                <Typography variant='body2' sx={{ mb: 2 }}>
                    This will delete {itemToDelete?.name ? <b>{itemToDelete.name}</b> : `the selected ${entityName.toLowerCase()}`} from the system.
                </Typography>
                <Alert severity='warning'>It will be permanently deleted and cannot be recovered.</Alert>
            </ConfirmationDialog>

        </Container>
    );
}
